import React from "react"
import "../styles.css"

export default function Home() {
  return (
    <div className="m-4 lg:ml-24 text-xl md:text-2xl">
      <nav className="pt-4 pb-4 z-10 text-gray-700 font-bold text-2xl sticky top-0 bg-white">
        <a href="#projects" className="mr-4 hover:text-gray-500">
          Projects
        </a>
        <a href="#contact" className="hover:text-gray-500 mr-4">
          Contact
        </a>
      </nav>
      <div className="flex flex-col h-screen relative max-w-1/4">
        <p className="p-6 mt-24 text-4xl md:text-5xl lg:text-6xl text-gray-800 font-patua">
          Hey, I'm Dan Fletcher. I'm a full-stack web developer.
        </p>

        <p className="p-6 text-2xl md:text-3xl text-gray-800 max-w-lg">
          I like to write{" "}
          <a
            href="https://technicallyfletch.com/"
            className="border-b-2 border-gray-700"
          >
            articles
          </a>
          , publish tutorials on{" "}
          <a
            href="https://www.youtube.com/channel/UCBab7VnuJvcBtp3CUvu_AHw"
            className="border-b-2 border-gray-700"
          >
            YouTube
          </a>{" "}
          and I love hacking on random things.
        </p>
      </div>
      <h2
        className="text-gray-700 font-bold text-4xl mb-16 mt-6 text-bebas uppercase"
        id="projects"
      >
        Projects
      </h2>
      <ul className="ml-4 mt-4">
        <li>
          <a
            href="https://www.npmjs.com/package/vue-model-factories"
            className="border-b-2 border-gray-700"
          >
            Vue Model Factories
          </a>
          <p className="ml-4 mt-8">
            A Laravel inspired factory library for seeding models in VueJS. I
            needed a way to seed complicated relationships for a high fidelity
            prototype required by a client I was working for.
          </p>
          <p className="ml-4 mt-2">
            It actually has no coupling to Vue since you can configure it with
            any adapter you like, but I was working a lot in Vue at the time so
            the defaults work with a Vuex store.
          </p>
        </li>
        <li className="mt-8">
          <a
            href="https://flashsightwords.com/"
            className="border-b-2 border-gray-700"
          >
            Flash Sight Words
          </a>
          <p className="ml-4 mt-8">
            Super simple app for practicing sight words with your kids. No user
            log ins or anything. The data comes from a Google Sheet that my wife
            and I use to create word lists.
          </p>
          <p className="ml-4 mt-2">
            One day I may add user registration and a real database so people
            can add their own.
          </p>
        </li>
        <li className="mt-8">
          <a
            href="http://danfletcherblog.ca/apps/apples_and_poop/index.html"
            className="border-b-2 border-gray-700"
          >
            Apples & Poop
          </a>
          <p className="ml-4 mt-8">
            My son, who was about 2 at the time, wanted to design a game. Using
            the Construct2 game engine I turned his crayon drawing into a real
            game! Works best on a phone.
          </p>
        </li>
      </ul>

      <h2
        className="text-gray-700 font-bold text-4xl text-bebas uppercase mt-24 mb-16"
        id="contact"
      >
        Contact
      </h2>
      <ul className="ml-4 mb-64">
        <li>
          <a
            href="https://twitter.com/danfletcherdev"
            className="border-b-2 border-gray-700 text-xl"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href="https://github.com/DanJFletcher"
            className="border-b-2 border-gray-700 text-xl"
          >
            GitHub
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/danjfletcher/"
            className="border-b-2 border-gray-700 text-xl"
          >
            LinkedIn
          </a>
        </li>
      </ul>
    </div>
  )
}
